import { useTranslation } from 'react-i18next'
import { useOperatorFilterStore } from 'src/hooks/useOperatorFilterStore'

import { AccordionWithCheckbox } from './accordion-with-checkbox'

type AccordionCityProps = {
  selectedCities: string[]
  onCityChange: (city: string) => void
}

export function AccordionCity({
  selectedCities,
  onCityChange,
}: AccordionCityProps) {
  const { t } = useTranslation()

  const { cities } = useOperatorFilterStore()

  return (
    <AccordionWithCheckbox
      title={t('page.operatorConsultation.modalOperatorFilter.cityTitle')}
      items={cities}
      selectedItems={selectedCities}
      onItemChange={onCityChange}
      searchEnabled
      searchPlaceholder={t(
        'page.operatorConsultation.modalOperatorFilter.searchCities',
      )}
    />
  )
}
