import { Translations } from '../i18n'

const enUsTranslations: Translations = {
  page: {
    operatorConsultation: {
      title: 'Operator Consultation',
      description:
        'Each transport operator has different rules for acceptance. Check payment methods and available operators by searching below.',
      state: {
        plural: 'States: {{state}}',
        singular: 'State: {{state}}',
      },
      city: {
        plural: 'Cities: {{city}}',
        singular: 'City: {{city}}',
      },
      meansOfTransport: {
        plural: 'Means of transport: {{meansOfTransport}}',
        singular: 'Means of transport: {{meansOfTransport}}',
      },
      basicInfo: 'Basic information',
      paymentMethods: 'Payment methods',
      whereToBuy: 'Where to buy',
      modalOperatorFilter: {
        filterBy: 'Filter by',
        clear: 'Clear',
        filter: 'Filter',
        stateTitle: 'State',
        cityTitle: 'City',
        meansOfTransportTitle: 'Transportation Facilities',
        creditPaymentTitle: 'Credit Payment',
        billetPaymentTitle: 'Billet Payment',
        yes: 'Yes',
        no: 'No',
        searchCities: 'Search cities',
        searchStates: 'Search states',
        searchMeansOfTransport: 'Search transportation facilities',
      },
    },
  },
  component: {
    cardOperator: {
      state: 'State: {{state}}',
    },
    operatorList: {
      emptyStateTitle: 'No operator found',
      emptyStateDescription: 'Check your search and try again.',
      searchLabel: 'Search',
    },
  },
}

export { enUsTranslations }
