import { ReactNode } from 'react'

import { ModalHeaderStyled } from './styles'

type HeaderProps = {
  children: ReactNode
  className?: string
}

export function Header({ children, className }: HeaderProps) {
  return <ModalHeaderStyled className={className}>{children}</ModalHeaderStyled>
}
