import {
  Checkbox,
  SearchField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { Accordion } from './accordion'
import { AccordionContentStyled, ListItemStyled, ListStyled } from './styles'

type AccordionWithCheckboxProps = {
  title: string
  items: string[]
  selectedItems: string[]
  onItemChange: (item: string) => void
  searchEnabled?: boolean
  searchPlaceholder?: string
}

export function AccordionWithCheckbox({
  title,
  items,
  selectedItems,
  onItemChange,
  searchEnabled = false,
  searchPlaceholder = '',
}: AccordionWithCheckboxProps) {
  const theme = useTheme()

  const [search, setSearch] = useState('')

  const filteredList = useMemo(() => {
    if (!searchEnabled) {
      return items
    }

    const regex = new RegExp(search, 'i')

    return items.filter((item) => regex.test(item))
  }, [items, search, searchEnabled])

  return (
    <Accordion title={title}>
      {searchEnabled && (
        <SearchField
          label={searchPlaceholder}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          disabled={items.length === 0}
        />
      )}

      <AccordionContentStyled addMarginTop={searchEnabled}>
        <ListStyled>
          {filteredList.map((item) => (
            <ListItemStyled key={item}>
              <Typography
                variant="body4"
                weight={600}
                variantColor={theme.colors.neutral30}
              >
                {item}
              </Typography>

              <Checkbox
                onChange={() => onItemChange(item)}
                checked={selectedItems.includes(item)}
              />
            </ListItemStyled>
          ))}
        </ListStyled>
      </AccordionContentStyled>
    </Accordion>
  )
}
