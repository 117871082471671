import { ReactNode } from 'react'

import { ModalContainerStyled } from './styles'

type RootProps = {
  children: ReactNode
  open: boolean
}

export function Root({ children, open }: RootProps) {
  return (
    <ModalContainerStyled open={open} aria-modal="true" role="dialog">
      <>{children}</>
    </ModalContainerStyled>
  )
}
