import { Translations } from '../i18n'

const ptBrTranslations: Translations = {
  page: {
    operatorConsultation: {
      title: 'Consulta de operadoras',
      description: `Cada operadora de transporte tem regras diferentes para aceitação.
      Confira formas de pagamento e operadoras disponíveis pesquisando
      abaixo.`,
      state: {
        plural: 'Estados: {{state}}',
        singular: 'Estado: {{state}}',
      },
      city: {
        plural: 'Cidades: {{city}}',
        singular: 'Cidade: {{city}}',
      },
      meansOfTransport: {
        plural: 'Meios de transporte: {{meansOfTransport}}',
        singular: 'Meio de transporte: {{meansOfTransport}}',
      },
      basicInfo: 'Informações básicas',
      paymentMethods: 'Forma de pagamento',
      whereToBuy: 'Onde comprar',
      modalOperatorFilter: {
        filterBy: 'Filtrar por',
        clear: 'Limpar',
        filter: 'Filtrar',
        stateTitle: 'Estado',
        cityTitle: 'Cidade',
        meansOfTransportTitle: 'Meio de transporte',
        creditPaymentTitle: 'Pagamento por crédito',
        billetPaymentTitle: 'Pagamento por boleto',
        yes: 'Sim',
        no: 'Não',
        searchCities: 'Buscar cidades',
        searchStates: 'Buscar estados',
        searchMeansOfTransport: 'Buscar meio de transporte',
      },
    },
  },
  component: {
    cardOperator: {
      state: 'Estado: {{state}}',
    },
    operatorList: {
      emptyStateTitle: 'Nenhuma operadora encontrada',
      emptyStateDescription: 'Revise a sua pesquisa e tente novamente.',
      searchLabel: 'Buscar',
    },
  },
}

export { ptBrTranslations }
