import { ReactNode } from 'react'

import { ModalContentStyled } from './styles'

type ContentProps = {
  children: ReactNode
  hasFooter?: boolean
  className?: string
}

export function Content({ children, hasFooter = false }: ContentProps) {
  return (
    <ModalContentStyled hasFooter={hasFooter}>{children}</ModalContentStyled>
  )
}
