import styled from 'styled-components'

export const HeaderContainerStyled = styled.header`
  display: flex;
  align-items: center;

  width: 100%;
  height: 3.5rem;

  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-top: ${({ theme }) => theme.spacings.xs2};
  padding-right: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs2};

  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral90};
`
