import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { OperatorConsultation } from '../pages/operator-consultation'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/vt-consult" element={<OperatorConsultation />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
