import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type TitleProps = {
  id: string
  children: string
}

export function Title({ id, children }: TitleProps) {
  const theme = useTheme()

  return (
    <Typography
      id={id}
      variant="headline7"
      variantColor={theme.colors.neutral20}
    >
      {children}
    </Typography>
  )
}
