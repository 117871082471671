import { useTranslation } from 'react-i18next'
import { useOperatorFilterStore } from 'src/hooks/useOperatorFilterStore'

import { AccordionWithCheckbox } from './accordion-with-checkbox'

type AccordionStateProps = {
  selectedStates: string[]
  onStateChange: (state: string) => void
}

export function AccordionState({
  selectedStates,
  onStateChange,
}: AccordionStateProps) {
  const { t } = useTranslation()

  const { states } = useOperatorFilterStore()

  return (
    <AccordionWithCheckbox
      title={t('page.operatorConsultation.modalOperatorFilter.stateTitle')}
      items={states}
      selectedItems={selectedStates}
      onItemChange={onStateChange}
      searchEnabled
      searchPlaceholder={t(
        'page.operatorConsultation.modalOperatorFilter.searchStates',
      )}
    />
  )
}
