import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { AccordionStyled } from './styles'

type AccordionProps = {
  title: string
  disabled?: boolean
  children: ReactNode
}

export function Accordion({ title, disabled, children }: AccordionProps) {
  const theme = useTheme()

  const renderTitle = useMemo(
    () => (
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral30}
        weight={700}
      >
        {title}
      </Typography>
    ),
    [theme.colors.neutral30, title],
  )

  return (
    <AccordionStyled
      variant="default"
      disabled={disabled}
      customHeader={renderTitle}
    >
      {children}
    </AccordionStyled>
  )
}
