import styled from 'styled-components'

export const ContainerStyled = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #ffffff;
`

export const WrapperMain = styled.main`
  display: flex;
  flex-direction: column;

  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-top: ${({ theme }) => theme.spacings.s};
  padding-right: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.s};

  row-gap: ${({ theme }) => theme.spacings.xs};
`

export const WrapperTextsStyled = styled.div`
  display: grid;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`
