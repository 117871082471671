import { useTranslation } from 'react-i18next'
import { Description } from 'src/components/description'
import { Header } from 'src/components/header'
import { OperatorList } from 'src/components/operator-list'
import { Title } from 'src/components/title'

import { ModalOperatorDetails } from './components/modal-operator-details'
import { ContainerStyled, WrapperMain, WrapperTextsStyled } from './styles'

export function OperatorConsultation() {
  const { t } = useTranslation()

  return (
    <ContainerStyled>
      <Header />

      <WrapperMain>
        <WrapperTextsStyled
          aria-labelledby="operator-consultation-title"
          aria-describedby="operator-consultation-description"
        >
          <Title id="operator-consultation-title">
            {t('page.operatorConsultation.title')}
          </Title>

          <Description id="operator-consultation-description">
            {t('page.operatorConsultation.description')}
          </Description>
        </WrapperTextsStyled>

        <OperatorList />
      </WrapperMain>

      <ModalOperatorDetails />
    </ContainerStyled>
  )
}
