import { enUsTranslations } from './en-us'
import { ptBrTranslations } from './pt-br'

const translations = {
  'pt-BR': { translations: ptBrTranslations },
  'en-US': { translations: enUsTranslations },
  'en-GB': { translations: enUsTranslations },
}

export default translations
