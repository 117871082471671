import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2'

export function renderSkeletons(count: number) {
  return Array.from({ length: count }).map((_, key) => (
    <Skeleton
      key={key}
      variant="rectangular"
      animation="wave"
      width="100%"
      height={64}
    />
  ))
}
