import { PillButton, SearchField } from '@flash-tecnologia/hros-web-ui-v2'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { trpc } from 'src/api/client'
import { useOperatorFilterStore } from 'src/hooks/useOperatorFilterStore'
import { ModalOperatorFilter } from 'src/pages/operator-consultation/components/modal-operator-filter'

import { Card } from './card'
import { EmptyState } from './empty-state'
import { renderSkeletons } from './render-skeletons'
import {
  ListWrapperStyled,
  OperatorListContainerStyled,
  WrapperSearchAndFiltersStyled,
} from './styles'

export type Operator = {
  operator: string
  state: string[]
  city: string[]
  flashCardPayment: boolean
  billetPayment: boolean
  pixPayment: boolean
  transportType: string[]
  sellingPoint: string
  isFlash: boolean
}

const filterBySearch = (operator: Operator, search: string) => {
  const regex = new RegExp(search, 'i')

  return regex.test(operator.operator)
}

const filterByState = (operator: Operator, states: string[]) => {
  return states.length
    ? states.some((state) => operator.state.includes(state))
    : true
}

const filterByCity = (operator: Operator, cities: string[]) => {
  return cities.length
    ? cities.some((state) => operator.city.includes(state))
    : true
}

const filterByTransportType = (
  operator: Operator,
  transportTypes: string[],
) => {
  return transportTypes.length
    ? transportTypes.some((state) => operator.transportType.includes(state))
    : true
}

const filterByPayment = (
  operatorPayment: boolean,
  filterPayment: boolean | null,
) => {
  return filterPayment !== null ? operatorPayment === filterPayment : true
}

export function OperatorList() {
  const { t } = useTranslation()
  const { data: operatorList = [], isLoading } = trpc.getVTOperators.useQuery()

  const [search, setSearch] = useState('')
  const { setFilterOptions, filters, openFilterModal } =
    useOperatorFilterStore()

  const filteredList = useMemo(() => {
    return operatorList.filter((item) => {
      const matchesSearch = filterBySearch(item, search)
      const matchesState = filterByState(item, filters.states)
      const matchesCity = filterByCity(item, filters.cities)
      const matchesFlashCardPayment = filterByPayment(
        item.flashCardPayment,
        filters.flashCardPayment,
      )
      const matchesBilletPayment = filterByPayment(
        item.billetPayment,
        filters.billetPayment,
      )
      const matchesTransportType = filterByTransportType(
        item,
        filters.transportTypes,
      )

      return (
        matchesSearch &&
        matchesState &&
        matchesCity &&
        matchesFlashCardPayment &&
        matchesBilletPayment &&
        matchesTransportType
      )
    })
  }, [filters, operatorList, search])

  useEffect(() => {
    if (operatorList.length) {
      setFilterOptions(operatorList)
    }
  }, [operatorList, setFilterOptions])

  const renderList = () => (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          itemCount={filteredList.length}
          itemSize={80}
          itemData={filteredList}
        >
          {({ index, style, data }) => (
            <Card index={index} style={style} data={data} />
          )}
        </List>
      )}
    </AutoSizer>
  )

  return (
    <OperatorListContainerStyled>
      <WrapperSearchAndFiltersStyled>
        <SearchField
          label={t('component.operatorList.searchLabel')}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          disabled={isLoading}
        />

        <PillButton
          icon="IconFilter"
          size="medium"
          type="primary"
          variant="default"
          onClick={openFilterModal}
        />
      </WrapperSearchAndFiltersStyled>

      <ListWrapperStyled>
        {isLoading ? (
          renderSkeletons(4)
        ) : filteredList.length > 0 ? (
          renderList()
        ) : (
          <EmptyState />
        )}
      </ListWrapperStyled>

      <ModalOperatorFilter />
    </OperatorListContainerStyled>
  )
}
