import { GenericProfilePicture, Icons } from '@flash-tecnologia/hros-web-ui-v2'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  InfosStyled,
  LocationStyled,
  NameSyled,
  OperatorCardContainerStyled,
  StateSyled,
} from './styles'

type CardOperatorProps = {
  imagem?: string
  name: string
  state: string
  style: CSSProperties
  disabled?: boolean
  onClick: () => void
}

export function CardOperator({
  name,
  state,
  imagem,
  style,
  disabled,
  onClick,
}: CardOperatorProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <OperatorCardContainerStyled
      style={style}
      aria-label={`${name}, ${state}`}
      role="button"
      disabled={disabled}
      onClick={onClick}
    >
      <GenericProfilePicture source={imagem} name={name} size={32} />

      <InfosStyled>
        <NameSyled variant="body4" variantColor={theme.colors.neutral30}>
          {name}
        </NameSyled>

        <LocationStyled>
          <Icons name="IconMapPin" size={12} color={theme.colors.neutral50} />

          <StateSyled variant="caption" variantColor={theme.colors.neutral50}>
            {t('component.cardOperator.state', { state })}
          </StateSyled>
        </LocationStyled>
      </InfosStyled>
    </OperatorCardContainerStyled>
  )
}
