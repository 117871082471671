import { Accordion as FlamingoAccordion } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

type AccordionContentStyledProps = {
  addMarginTop: boolean
}

export const AccordionStyled = styled(FlamingoAccordion)`
  .MuiButtonBase-root {
    padding: ${({ theme }) => theme.spacings.xs2};
  }

  .MuiAccordionDetails-root {
    padding: ${({ theme }) => theme.spacings.xs2};
  }

  .Mui-disabled {
    opacity: 0.6;

    cursor: not-allowed;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  ::before {
    background-color: transparent;
  }
`

export const WrapperAccordionContentStyled = styled.div`
  display: grid;

  row-gap: ${({ theme }) => theme.spacings.xs4};
`

export const AccordionContentStyled = styled.div<AccordionContentStyledProps>`
  max-height: 312px;

  overflow: hidden scroll;

  margin-top: ${({ theme, addMarginTop }) =>
    addMarginTop ? theme.spacings.xs4 : '0px'};
`

export const ListStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs4};
`

export const ListItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
