import { Button } from '@flash-tecnologia/hros-web-ui-v2'
import { Modal } from 'src/components/modal'
import styled from 'styled-components'

export const HeaderStyled = styled(Modal.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 4.625rem;

  column-gap: ${({ theme }) => theme.spacings.xs4};
`

export const ButtonCloseStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.spacings.s};
`

export const CollapsibleSectionsStyled = styled.div`
  display: grid;

  row-gap: ${({ theme }) => theme.spacings.xs2};

  .Mui-expanded {
    margin: 0;
  }
`

export const ButtonConfirmStyled = styled(Button)`
  width: 100%;
`
