import {
  LinkButton,
  PillButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'src/components/modal'
import {
  Filters,
  useOperatorFilterStore,
} from 'src/hooks/useOperatorFilterStore'
import { useTheme } from 'styled-components'

import { AccordionBilletPayment } from './accordions/accordion-billet-payment'
import { AccordionCity } from './accordions/accordion-city'
import { AccordionCreditPayment } from './accordions/accordion-credit-payment'
import { AccordionMeansOfTransport } from './accordions/accordion-means-of-transport'
import { AccordionState } from './accordions/accordion-state'
import {
  ButtonConfirmStyled,
  CollapsibleSectionsStyled,
  HeaderStyled,
} from './styles'

const updateFilter = (
  prevFilters: Filters,
  key: keyof Filters,
  value: string,
) => {
  const currentValues = prevFilters[key] as string[]

  const updatedValues = currentValues.includes(value)
    ? currentValues.filter((item) => item !== value)
    : [...currentValues, value]

  return { ...prevFilters, [key]: updatedValues }
}

export function ModalOperatorFilter() {
  const { t } = useTranslation()

  const { setFilters, resetFilters, isFilterModalOpen, closeFilterModal } =
    useOperatorFilterStore()

  const [localFilters, setLocalFilters] = useState<Filters>({
    states: [],
    cities: [],
    flashCardPayment: null,
    billetPayment: null,
    transportTypes: [],
  })

  const theme = useTheme()

  const handleStateChange = useCallback((state: string) => {
    setLocalFilters((prev) => updateFilter(prev, 'states', state))
  }, [])

  const handleCityChange = useCallback((city: string) => {
    setLocalFilters((prev) => updateFilter(prev, 'cities', city))
  }, [])

  const handleMeansOfTransportChange = useCallback((transport: string) => {
    setLocalFilters((prev) => updateFilter(prev, 'transportTypes', transport))
  }, [])

  const handleCreditPaymentChange = useCallback((payment: boolean | null) => {
    setLocalFilters((prev) => ({
      ...prev,
      flashCardPayment: payment,
    }))
  }, [])

  const handleBilletPaymentChange = useCallback((payment: boolean | null) => {
    setLocalFilters((prev) => ({
      ...prev,
      billetPayment: payment,
    }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilters(localFilters)
    closeFilterModal()
  }, [closeFilterModal, localFilters, setFilters])

  const clearFilter = useCallback(() => {
    resetFilters()
    setLocalFilters({
      states: [],
      cities: [],
      flashCardPayment: null,
      billetPayment: null,
      transportTypes: [],
    })
    closeFilterModal()
  }, [closeFilterModal, resetFilters])

  return (
    <Modal.Root open={isFilterModalOpen}>
      <HeaderStyled>
        <Typography variant="headline9" variantColor={theme.colors.neutral30}>
          {t('page.operatorConsultation.modalOperatorFilter.filterBy')}
        </Typography>

        <PillButton
          icon="IconX"
          size="small"
          type="secondary"
          variant="default"
          onClick={closeFilterModal}
        />
      </HeaderStyled>

      <Modal.Content hasFooter>
        <CollapsibleSectionsStyled>
          <AccordionState
            selectedStates={localFilters.states}
            onStateChange={handleStateChange}
          />

          <AccordionCity
            selectedCities={localFilters.cities}
            onCityChange={handleCityChange}
          />

          <AccordionCreditPayment
            selectedCreditPayment={localFilters.flashCardPayment}
            onCreditPaymentChange={handleCreditPaymentChange}
          />

          <AccordionBilletPayment
            selectedBilletPayment={localFilters.billetPayment}
            onBilletPaymentChange={handleBilletPaymentChange}
          />

          <AccordionMeansOfTransport
            selectedMeansOfTransport={localFilters.transportTypes}
            onMeansOfTransportChange={handleMeansOfTransportChange}
          />
        </CollapsibleSectionsStyled>
      </Modal.Content>

      <Modal.Footer>
        <LinkButton variant="neutral" onClick={clearFilter}>
          {t('page.operatorConsultation.modalOperatorFilter.clear')}
        </LinkButton>

        <ButtonConfirmStyled
          size="medium"
          variant="primary"
          onClick={applyFilter}
        >
          {t('page.operatorConsultation.modalOperatorFilter.filter')}
        </ButtonConfirmStyled>
      </Modal.Footer>
    </Modal.Root>
  )
}
