import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AccordionWithCheckbox } from './accordion-with-checkbox'

type AccordionBilletPaymentProps = {
  selectedBilletPayment: boolean | null
  onBilletPaymentChange: (value: boolean | null) => void
}

export function AccordionBilletPayment({
  selectedBilletPayment,
  onBilletPaymentChange,
}: AccordionBilletPaymentProps) {
  const { t } = useTranslation()

  const handleCheckboxChange = useCallback(
    (value: boolean) => {
      onBilletPaymentChange(selectedBilletPayment === value ? null : value)
    },
    [onBilletPaymentChange, selectedBilletPayment],
  )

  return (
    <AccordionWithCheckbox
      title={t(
        'page.operatorConsultation.modalOperatorFilter.billetPaymentTitle',
      )}
      items={[
        t('page.operatorConsultation.modalOperatorFilter.yes'),
        t('page.operatorConsultation.modalOperatorFilter.no'),
      ]}
      selectedItems={
        selectedBilletPayment === true
          ? [t('page.operatorConsultation.modalOperatorFilter.yes')]
          : selectedBilletPayment === false
            ? [t('page.operatorConsultation.modalOperatorFilter.no')]
            : []
      }
      onItemChange={(item) =>
        handleCheckboxChange(
          item === t('page.operatorConsultation.modalOperatorFilter.yes'),
        )
      }
    />
  )
}
