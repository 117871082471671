import { Accordion as FlamingoAccordion } from '@flash-tecnologia/hros-web-ui-v2'
import { Modal } from 'src/components/modal'
import styled from 'styled-components'

export const HeaderStyled = styled(Modal.Header)`
  display: flex;
  align-items: center;

  column-gap: ${({ theme }) => theme.spacings.xs4};
`

export const ButtonCloseStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.spacings.s};
`

export const OperatorDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs3};

  p {
    text-align: center;
  }
`

export const OperatorCollapsibleSections = styled.div`
  display: grid;

  row-gap: ${({ theme }) => theme.spacings.xs2};

  .Mui-expanded {
    margin: 0;
  }

  margin-top: ${({ theme }) => theme.spacings.xs};
`

export const AccordionStyled = styled(FlamingoAccordion)`
  .MuiButtonBase-root {
    padding: ${({ theme }) => theme.spacings.xs2};
  }

  .MuiAccordionDetails-root {
    padding: ${({ theme }) => theme.spacings.xs2};
  }

  .Mui-disabled {
    opacity: 0.6;

    cursor: not-allowed;
  }

  ::before {
    background-color: transparent;
  }
`

export const WrapperAccordionContentStyled = styled.div`
  display: grid;

  row-gap: ${({ theme }) => theme.spacings.xs4};
`
export const AccordionContentStyled = styled.div`
  display: flex;
  align-items: flex-start;

  column-gap: 12px;
`

export const IconWrapperStyled = styled.div`
  width: ${({ theme }) => theme.spacings.xs2};
  height: ${({ theme }) => theme.spacings.xs2};
`
