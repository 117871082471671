import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AccordionWithCheckbox } from './accordion-with-checkbox'

type AccordionCreditPaymentProps = {
  selectedCreditPayment: boolean | null
  onCreditPaymentChange: (value: boolean | null) => void
}

export function AccordionCreditPayment({
  selectedCreditPayment,
  onCreditPaymentChange,
}: AccordionCreditPaymentProps) {
  const { t } = useTranslation()

  const handleCheckboxChange = useCallback(
    (value: boolean) => {
      onCreditPaymentChange(selectedCreditPayment === value ? null : value)
    },
    [onCreditPaymentChange, selectedCreditPayment],
  )

  return (
    <AccordionWithCheckbox
      title={t(
        'page.operatorConsultation.modalOperatorFilter.creditPaymentTitle',
      )}
      items={[
        t('page.operatorConsultation.modalOperatorFilter.yes'),
        t('page.operatorConsultation.modalOperatorFilter.no'),
      ]}
      selectedItems={
        selectedCreditPayment === true
          ? [t('page.operatorConsultation.modalOperatorFilter.yes')]
          : selectedCreditPayment === false
            ? [t('page.operatorConsultation.modalOperatorFilter.no')]
            : []
      }
      onItemChange={(item) =>
        handleCheckboxChange(
          item === t('page.operatorConsultation.modalOperatorFilter.yes'),
        )
      }
    />
  )
}
