import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const OperatorCardContainerStyled = styled.button`
  display: flex;
  align-items: center;

  column-gap: ${({ theme }) => theme.spacings.xs4};

  padding-left: ${({ theme }) => theme.spacings.xs4};
  padding-top: ${({ theme }) => theme.spacings.xs3};
  padding-right: ${({ theme }) => theme.spacings.xs4};
  padding-bottom: ${({ theme }) => theme.spacings.xs3};

  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral90};

  max-height: ${({ theme }) => theme.spacings.l};

  &:disabled {
    opacity: 0.6;
  }
`

export const InfosStyled = styled.div`
  display: flex;
  align-items: flex-start;

  flex-direction: column;
`

export const NameSyled = styled(Typography)`
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 576px) {
    max-width: 400px;
  }

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 600px;
  }

  @media (min-width: 700px) {
    max-width: 600px;
  }

  @media (min-width: 1200px) {
    max-width: 980px;
  }
`

export const StateSyled = styled(Typography)`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 576px) {
    max-width: 300px;
  }

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 992px) {
    max-width: 500px;
  }

  @media (min-width: 1200px) {
    max-width: 580px;
  }
`

export const LocationStyled = styled.div`
  display: flex;
  align-items: center;

  column-gap: ${({ theme }) => theme.spacings.xs4};
`
