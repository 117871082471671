import { CSSProperties } from 'react'
import { useOperatorConsultationStore } from 'src/hooks/useOperatorConsultationStore'

import { CardOperator } from '../card-operator'
import { Operator } from '.'

type CardProps = {
  index: number
  style: CSSProperties
  data: Operator[]
}

function formatDetails(data: Operator) {
  const transactionMethods = []

  if (data.flashCardPayment) transactionMethods.push('Cartão')
  if (data.billetPayment) transactionMethods.push('Boleto')
  if (data.pixPayment) transactionMethods.push('PIX')

  return {
    name: data.operator,
    state: data.state,
    city: data.city,
    transactionMethods,
    sellingPoint: data.sellingPoint,
    transportType: data.transportType,
  }
}

export function Card({ index, style, data }: CardProps) {
  const { openDetailsModal } = useOperatorConsultationStore()

  const item = data[index]

  const handleOpenDetails = () => {
    const formattedDetails = formatDetails(item)

    openDetailsModal(formattedDetails)
  }

  return (
    <CardOperator
      style={style}
      name={item.operator}
      state={item.state.join(', ')}
      onClick={handleOpenDetails}
    />
  )
}
