import { useTranslation } from 'react-i18next'
import { useOperatorFilterStore } from 'src/hooks/useOperatorFilterStore'

import { AccordionWithCheckbox } from './accordion-with-checkbox'

type AccordionMeansOfTransportProps = {
  selectedMeansOfTransport: string[]
  onMeansOfTransportChange: (transport: string) => void
}

export function AccordionMeansOfTransport({
  selectedMeansOfTransport,
  onMeansOfTransportChange,
}: AccordionMeansOfTransportProps) {
  const { t } = useTranslation()

  const { transportTypes } = useOperatorFilterStore()

  return (
    <AccordionWithCheckbox
      title={t(
        'page.operatorConsultation.modalOperatorFilter.meansOfTransportTitle',
      )}
      items={transportTypes}
      selectedItems={selectedMeansOfTransport}
      onItemChange={onMeansOfTransportChange}
      searchEnabled
      searchPlaceholder={t(
        'page.operatorConsultation.modalOperatorFilter.searchMeansOfTransport',
      )}
    />
  )
}
