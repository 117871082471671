import { Operator } from 'src/components/operator-list'
import { create } from 'zustand'

type OperatorKeys = keyof Pick<Operator, 'state' | 'city' | 'transportType'>

export type Filters = {
  states: string[]
  cities: string[]
  flashCardPayment: boolean | null
  billetPayment: boolean | null
  transportTypes: string[]
}

type OperatorFiltersState = {
  filters: Filters
  cities: string[]
  states: string[]
  transportTypes: string[]
  isFilterModalOpen: boolean
}

type OperatorFiltersActions = {
  setFilters: (filter: Filters) => void
  setFilterOptions: (operators: Operator[]) => void
  openFilterModal: () => void
  closeFilterModal: () => void
  resetFilters: () => void
}

type OperatorFiltersStore = OperatorFiltersState & OperatorFiltersActions

const extractUniqueValues = (key: OperatorKeys, operatorList: Operator[]) => {
  const values = operatorList.flatMap((operator) => operator[key])

  const regexHTTP = /http|n\/a*/i

  return [...new Set(values)]
    .filter((value) => value && !regexHTTP.test(value))
    .sort((firstValue, secondValue) => firstValue.localeCompare(secondValue))
}

const INITIAL_FILTERS: Filters = {
  states: [],
  cities: [],
  flashCardPayment: null,
  billetPayment: null,
  transportTypes: [],
}

const initialState: OperatorFiltersState = {
  filters: INITIAL_FILTERS,
  cities: [],
  states: [],
  transportTypes: [],
  isFilterModalOpen: false,
}

export const useOperatorFilterStore = create<OperatorFiltersStore>((set) => ({
  ...initialState,
  setFilters: (filters) => set((state) => ({ ...state, filters })),
  setFilterOptions: (operators) => {
    const cities = extractUniqueValues('city', operators)
    const states = extractUniqueValues('state', operators)
    const transportTypes = extractUniqueValues('transportType', operators)

    set({ cities, states, transportTypes })
  },
  openFilterModal: () => set({ isFilterModalOpen: true }),
  closeFilterModal: () => set({ isFilterModalOpen: false }),
  resetFilters: () => set({ filters: INITIAL_FILTERS }),
}))
