import { ReactNode } from 'react'

import { ModalFooterStyled } from './styles'

type FooterProps = {
  children: ReactNode
}

export function Footer({ children }: FooterProps) {
  return <ModalFooterStyled>{children}</ModalFooterStyled>
}
