import { Content } from './content'
import { Footer } from './footer'
import { Header } from './header'
import { Root } from './root'

export const Modal = {
  Root,
  Header,
  Content,
  Footer,
}
