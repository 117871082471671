import styled from 'styled-components'

export const OperatorListContainerStyled = styled.div``

export const WrapperSearchAndFiltersStyled = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  justify-content: center;

  > .pillButton-custom-theme-line {
    width: auto !important; // Adicionado o important, pois o componente coloca como 100% e não fica redondo
    align-self: center !important; // Adicionado o important, pois o componente coloca como flex-start
  }
`

export const ListWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  width: 100%;
  height: 62vh;

  margin-top: ${({ theme }) => theme.spacings.xs};
`

export const ListEmptyWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 19.5rem;

  margin: 0 auto;

  text-align: center;

  > h5 {
    margin-top: ${({ theme }) => theme.spacings.s};
  }

  > p {
    margin-top: ${({ theme }) => theme.spacings.xs2};
  }
`
