import { Icons, IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

import { AccordionContentStyled, IconWrapperStyled } from './styles'

type AccordionContentProps = {
  children: string | JSX.Element[]
  iconName: IconTypes
}

export function AccordionContent({
  iconName,
  children,
}: AccordionContentProps) {
  const theme = useTheme()

  return (
    <AccordionContentStyled>
      <IconWrapperStyled>
        <Icons name={iconName} size={20} color={theme.colors.primary} />
      </IconWrapperStyled>

      <Typography
        variant="body4"
        variantColor={theme.colors.neutral10}
        weight={600}
      >
        {children}
      </Typography>
    </AccordionContentStyled>
  )
}
