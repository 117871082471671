import './i18n'

import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2'
import { QueryClientProvider } from '@tanstack/react-query'
import { Trans } from 'react-i18next'

import { queryClient, trpc, trpcClient } from './api/client'
import AppRouter from './routes'

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Trans i18nKey="translations">
            <AppRouter />
          </Trans>
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
