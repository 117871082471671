import { useTranslation } from 'react-i18next'

import { Description } from '../description'
import { EmptyStateImage } from '../empty-state-image'
import { Title } from '../title'
import { ListEmptyWrapperStyled } from './styles'

export function EmptyState() {
  const { t } = useTranslation()

  return (
    <ListEmptyWrapperStyled
      aria-labelledby="no-operator-title"
      aria-describedby="no-operator-description"
      role="alert"
    >
      <EmptyStateImage
        title={t('component.operatorList.emptyStateTitle')}
        desc={t('component.operatorList.emptyStateDescription')}
      />

      <Title id="no-operator-title">
        {t('component.operatorList.emptyStateTitle')}
      </Title>

      <Description id="no-operator-description">
        {t('component.operatorList.emptyStateDescription')}
      </Description>
    </ListEmptyWrapperStyled>
  )
}
