import { FlashLogo } from '../flash-logo'
import { HeaderContainerStyled } from './styles'

export function Header() {
  return (
    <HeaderContainerStyled>
      <FlashLogo />
    </HeaderContainerStyled>
  )
}
