import { Modal as ModalMui } from '@mui/material'
import styled from 'styled-components'

type ModalContentStyledProps = {
  hasFooter: boolean
}

const modalHeaderHeight = '3.5rem'
const modalFooterHeight = '6rem'

export const ModalContainerStyled = styled(ModalMui)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.colors.neutral100};
  }
`

export const ModalHeaderStyled = styled.div`
  height: ${modalHeaderHeight};

  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-top: ${({ theme }) => theme.spacings.xs2};
  padding-right: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs2};
`

export const ModalContentStyled = styled.div<ModalContentStyledProps>`
  height: ${({ hasFooter }) =>
    hasFooter
      ? `calc(100% - ${modalHeaderHeight} - (${modalFooterHeight} + 1rem))`
      : `calc(100% - ${modalHeaderHeight})`};

  overflow-y: auto;

  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-top: ${({ theme }) => theme.spacings.s};
  padding-right: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.s};
`

export const ModalFooterStyled = styled.div`
  display: flex;
  align-items: center;

  column-gap: ${({ theme }) => theme.spacings.s};

  height: 6rem;

  padding: ${({ theme }) => theme.spacings.xs};

  border-style: solid;
  border-top-color: ${({ theme }) => theme.colors.neutral90};
  border-top-width: ${({ theme }) => theme.borders.width.xs2};
`
