import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type DescriptionProps = {
  id: string
  children: string
}

export function Description({ id, children }: DescriptionProps) {
  const theme = useTheme()

  return (
    <Typography id={id} variant="body3" variantColor={theme.colors.neutral50}>
      {children}
    </Typography>
  )
}
