import { create } from 'zustand'

type OperatorDetails = {
  name: string
  state: string[]
  city: string[]
  transactionMethods: string[]
  sellingPoint: string
  transportType: string[]
}

type OperatorConsultationState = {
  isDetailsModalOpen: boolean
  operatorDetails: OperatorDetails | null
}

type OperatorConsultationActions = {
  openDetailsModal: (details: OperatorDetails) => void
  closeDetailsModal: () => void
}

type OperatorConsultationStore = OperatorConsultationState &
  OperatorConsultationActions

const initialState: OperatorConsultationState = {
  isDetailsModalOpen: false,
  operatorDetails: null,
}

export const useOperatorConsultationStore = create<OperatorConsultationStore>(
  (set) => ({
    ...initialState,

    openDetailsModal: (details: OperatorDetails) => {
      set({ isDetailsModalOpen: true, operatorDetails: details })
    },

    closeDetailsModal: () => {
      set(initialState)
    },
  }),
)
