import {
  GenericProfilePicture,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { TFunction, useTranslation } from 'react-i18next'
import { Modal } from 'src/components/modal'
import { useOperatorConsultationStore } from 'src/hooks/useOperatorConsultationStore'
import { useTheme } from 'styled-components'

import { Accordion } from './accordion'
import { AccordionContent } from './accordion-content'
import {
  ButtonCloseStyled,
  HeaderStyled,
  OperatorCollapsibleSections,
  OperatorDetailsStyled,
  WrapperAccordionContentStyled,
} from './styles'

function renderTextWithLineBreaks(text: string) {
  return text.split('\n').map((line, index) => (
    <span key={index}>
      {line} <br />
    </span>
  ))
}

function formatDetailsText(
  t: TFunction<'translations', undefined>,
  detailsArray: string[],
  singularKey: string,
  pluralKey: string,
) {
  const key = detailsArray.length > 1 ? pluralKey : singularKey

  return t(key as never, {
    [key.includes('state')
      ? 'state'
      : key.includes('city')
        ? 'city'
        : 'meansOfTransport']: detailsArray.join(', '),
  })
}

export function ModalOperatorDetails() {
  const { isDetailsModalOpen, closeDetailsModal, operatorDetails } =
    useOperatorConsultationStore()
  const theme = useTheme()
  const { t } = useTranslation()

  const states =
    operatorDetails?.state &&
    formatDetailsText(
      t,
      operatorDetails.state,
      'page.operatorConsultation.state.singular',
      'page.operatorConsultation.state.plural',
    )

  const cities =
    operatorDetails?.city &&
    formatDetailsText(
      t,
      operatorDetails.city,
      'page.operatorConsultation.city.singular',
      'page.operatorConsultation.city.plural',
    )

  const meansOfTransport =
    operatorDetails?.transportType &&
    formatDetailsText(
      t,
      operatorDetails.transportType,
      'page.operatorConsultation.meansOfTransport.singular',
      'page.operatorConsultation.meansOfTransport.plural',
    )

  const transactionMethods = operatorDetails?.transactionMethods.join(', ')

  const whereToBuy = operatorDetails?.sellingPoint

  return (
    <Modal.Root open={isDetailsModalOpen}>
      <HeaderStyled>
        <ButtonCloseStyled type="button" onClick={closeDetailsModal}>
          <Icons name="IconArrowLeft" color={theme.colors.neutral30} />
        </ButtonCloseStyled>

        <Typography variant="headline9" variantColor={theme.colors.neutral30}>
          {t('page.operatorConsultation.title')}
        </Typography>
      </HeaderStyled>

      <Modal.Content>
        <OperatorDetailsStyled>
          <Typography variant="headline9" variantColor={theme.colors.neutral30}>
            {operatorDetails?.name}
          </Typography>

          <GenericProfilePicture name={operatorDetails?.name} size={64} />
        </OperatorDetailsStyled>

        <OperatorCollapsibleSections>
          <Accordion
            title={t('page.operatorConsultation.basicInfo')}
            disabled={!(states && cities && meansOfTransport)}
          >
            <WrapperAccordionContentStyled>
              {states && (
                <AccordionContent iconName="IconMapPin">
                  {states}
                </AccordionContent>
              )}

              {cities && (
                <AccordionContent iconName="IconMap">{cities}</AccordionContent>
              )}

              {meansOfTransport && (
                <AccordionContent iconName="IconBusStop">
                  {meansOfTransport}
                </AccordionContent>
              )}
            </WrapperAccordionContentStyled>
          </Accordion>

          <Accordion
            title={t('page.operatorConsultation.paymentMethods')}
            disabled={!transactionMethods}
          >
            <WrapperAccordionContentStyled>
              <AccordionContent iconName="IconShoppingCart">
                {transactionMethods}
              </AccordionContent>
            </WrapperAccordionContentStyled>
          </Accordion>

          <Accordion
            title={t('page.operatorConsultation.whereToBuy')}
            disabled={!whereToBuy}
          >
            <WrapperAccordionContentStyled>
              <AccordionContent iconName="IconMapPin">
                {whereToBuy && renderTextWithLineBreaks(whereToBuy)}
              </AccordionContent>
            </WrapperAccordionContentStyled>
          </Accordion>
        </OperatorCollapsibleSections>
      </Modal.Content>
    </Modal.Root>
  )
}
